<template>

  <div>
    <van-nav-bar
        title="在线充值电费"
        :left-arrow="isarrow"
        @click-left="onClickLeft"

    />
    <h1></h1>


    <div>
      <van-cell-group inset>
        <van-cell title="户号信息：" icon="friends-o" v-model="doorname"></van-cell>
        <van-cell title="电表号：" icon="friends-o" v-model="meterNumber"></van-cell>
        <van-cell title="缴费单位" icon="shop-o" value="淄博方安商务运营服务有限公司"></van-cell>
        <van-cell title="手机" icon="phone-o" v-model="mobile"></van-cell>
        <van-cell title="电费充值账单" icon="balance-list-o" is-link :to="url" value="查看详情"></van-cell>
        <van-cell title="可用余额（元）" icon="friends-o" v-model="advaceCharge"></van-cell>
      </van-cell-group>
      <h2 class="van-doc-demo-block__title"></h2>
      <van-cell-group inset class="jaofei">
        <van-cell center title="缴费金额" icon="gold-coin-o" value="" label="">

          <template #title>
            <span class="custom-title">缴费金额</span>

          </template>
          <van-field
              readonly
              clickable
              :value="price"
              @touchstart.native.stop="show = true"
          />
          <van-number-keyboard
              :show="show"
              theme="custom"
              v-model="price"
              extra-key="."
              close-button-text="完成"
              @blur="show = false"
              @input="onInput"
              @delete="onDelete"
          />
        </van-cell>
      </van-cell-group>
      <h2 class="van-doc-demo-block__title"></h2>
      <h2 class="van-doc-demo-block__title"></h2>
      <van-cell-group inset class="jaofei">
        <van-button type="primary" :disabled="dis" size="large" @click="pay">立即缴费</van-button>
      </van-cell-group>
    </div>
    <div class="van-contact-list__bottom">

    </div>
  </div>

</template>

<script>
import {
  Button,
  Field,
  Cell,
  CellGroup,
  List,
  NavBar,
  ContactList,
  Tag,
  Popup,
  Dialog,
  NumberKeyboard,
  Toast
} from 'vant';

//import jweixin from 'jweixin-module' ;
import {queryDoorNoList, getUnifiedorderResult} from "@/api/wxmppay"
//import {getRelativeTime} from "@/lib/tools"
import {getOpenid} from '@/utils/auth'
import {getLocalStorage, setLocalStorage} from '@/utils/local-storage';

export default {
  name: "pay",

  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [List.name]: List,
    [NavBar.name]: NavBar,
    [ContactList.name]: ContactList,
    [Tag.name]: Tag,
    [Popup.name]: Popup,
    [Dialog.name]: Dialog,
    [Field.name]: Field,
    [NumberKeyboard.name]: NumberKeyboard,
    [Toast.name]: Toast

  },
  data() {
    return {
      show: false,
      list: [],
      loading: false,
      finished: false,
      isarrow: true,
      dis: false,
      data: {
        getopenid: "",
        no: "",
        price: "",
        token: ""
      },
      query: {
        no: "",
        getopenid: ""
      },
      totalpay: "",
      doorname: "",
      meterNumber: "",
      advaceCharge: "",
      mobile: "",
      url: "",
      price: "",
      token: ""
    };
  },
  created() {
    this.query.no = this.$route.query.no
    this.query.getopenid = getOpenid()
    //根据房间号获取欠费数据
    queryDoorNoList(this.query).then(res => {
      if (res.return_code == "202") {
        Dialog.alert({
          message: res.return_msg,
        }).then(() => {
          // on close
          //this.$router.back(-1)
          this.$router.push('/');
          return;
        });


      }
      if (res.return_code == "201") {
        Dialog.alert({
          message: res.return_msg,
        }).then(() => {
          // on close
          this.$router.push('/');
          return;
        });


      }
      console.log(res.fdElecricBase.MeterNumber);
      this.doorname = res.fdElecricBase.fcCell.Name
      this.meterNumber = res.fdElecricBase.MeterNumber
      this.advaceCharge = res.fdElecricBase.AdvaceCharge
      this.mobile = res.mobile
      this.token = res.token
      this.url = "/orderlist/?doorno=" + this.query.no
    })
  }
  ,
  methods: {
    onLoad() {
    },
    pay() {
      this.dis = true
      //获取支付信息
      this.data.getopenid = getOpenid()
      this.data.no = this.query.no
      this.data.price = this.price
      this.data.token = this.token
      if (this.price == "") {
        Dialog.alert({
          message: "充值金额未填写"
        })
        this.dis = false
        return;
      }
      getUnifiedorderResult(this.data).then(res => {
        if (res.return_code == "206") {
          Dialog.alert({
            message: res.return_msg,
          }).then(() => {
            // on close
            this.$router.push('/');
            return;
          });


        }
        /**
         * 微信支付
         * @type {string}
         */
        let prepay_data = JSON.stringify({
          appId: res.AppId,
          timeStamp: res.TimeStamp,
          nonceStr: res.NonceStr,
          package: res.Package,
          signType: 'MD5',
          paySign: res.PaySign
        });
        setLocalStorage({prepay_data: prepay_data});
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
          }
        } else {
          this.onBridgeReady();
        }

      })


    },

    onClickLeft() {
      this.$router.push('/');
    }
    ,
    onInput(value) {
      Toast(value);
    }
    ,
    onDelete() {
      Toast('删除');
    },
    onBridgeReady() {
      let that = this;
      let data = getLocalStorage('prepay_data');
      WeixinJSBridge.invoke('getBrandWCPayRequest', JSON.parse(data.prepay_data),
          function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              that.$router.replace({
                name: 'paymentStatus',
                params: {
                  doorno:that.query.no,
                  status: 'success'
                }
              });
            } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
              that.$router.replace({
                name: 'paymentStatus',
                params: {
                  doorno:that.query.no,
                  status: 'cancel'
                }
              });
            } else {
              that.$router.replace({
                name: 'paymentStatus',
                params: {
                  doorno:that.query.no,
                  status: 'failed'
                }
              });

            }
          });


    }
  },
}

</script>

<style scoped>
.paybg {
  background: #1677ff;
}

.jiaofei {
  margin-top: 15px;
}
</style>